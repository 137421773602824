<template>
    <div class="page">
        <Header />
        <div class="page_body">
            <div class="titlt_bg"></div>
            <div class="content">
                <!-- <img style="width: 100%" :src="agreement[type]" /> -->
                <div class="menu">
                    <div
                        class="menu_item"
                        v-for="(item,index) in menuList"
                        :key="index"
                        :class="alias == item.alias ? 'active' : ''"
                        @click="changeAlias(item.alias)"
                    >{{ item.title }}</div>
                </div>
                <!-- // 富文本 -->
                <div class="content_box">
                    <template v-if="articleList.length > 0">
                        <div v-for="item in articleList" :key="item.id" class="article_item" @click="toDetail(item.id)">
                            <div class="title">{{ item.title }}</div>
                            <div class="summary">{{ item.summary }}</div>
                        </div>
                        <el-pagination
                            layout="prev, pager, next"
                            :page-size="limit"
                            :total="total"
                            @current-change="handleCurrentChange"
                            class="pagination"
                        />
                    </template>
                    <el-empty :image-size="100" description="暂无数据" v-else />
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/header.vue';
import Footer from '@/components/footer.vue';
import { scrollTo } from '@/utils/scroll-to.js';
import { osCmsCategorys, osCmsCategoryPost } from '@/api/osCms';
export default {
    name: 'News',
    components: {
        Header,
        Footer,
    },
    data() {
        return {
            alias: '',
            menuList: [],
            articleList: [],
            page: 1,
            limit: 15,
            total: 0,
        };
    },
    created() {
        this.getMenu();
    },
    mounted() {
        scrollTo(0, 800);
    },
    methods: {
        getMenu() {
            osCmsCategorys({ 'page.size': -1 }).then(res => {
                console.log('栏目', res);
                let data = res;
                if (data.status == 'OK') {
                    this.menuList = data.content.list;
                    if (this.menuList.length > 0) {
                        this.alias = this.menuList[0].alias;
                        this.getArticle();
                    }
                }
            });
        },
        getArticle() {
            osCmsCategoryPost({ c: this.alias, 'page.current': this.page, 'page.size': this.limit }).then(res => {
                console.log('文章', res);
                let data = res;
                if (data.status == 'OK') {
                    this.articleList = data.content.list;
                    this.total = data.content.total;
                }
            });
        },
        handleCurrentChange(val) {
            this.page = val;
            this.getArticle();
        },
        changeAlias(alias) {
            scrollTo(0, 800);
            this.alias = alias;
            this.page = 1;
            this.limit = 15;
            this.total = 0;
            this.getArticle();
        },
        toDetail(id) {
            this.$router.push({
                name: 'Detail',
                query: {
                    id,
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
// Layout
.page {
    width: 100%;
    background-color: #f4f4f4;

    position: relative;

    .titlt_bg {
        width: 100%;
        height: 450px;
        background-color: #666;
        background: url('../../assets/background/yinsi.png') no-repeat;
        background-size: 100% 100%;
    }

    .content {
        width: 1000px;
        min-height: 500px;
        margin: -80px auto 0 auto;
        background-color: #fff;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        display: flex;
        justify-content: space-between;

        .menu {
            width: 200px;
            text-align: center;
            flex: 0 0 auto;
            margin-top: 50px;

            .menu_item {
                font-size: 16px;
                padding: 15px 0;
                cursor: pointer;
                margin-bottom: 20px;
            }

            .active {
                position: relative;
                &::after {
                    content: '';
                    background-color: #9a609c;
                    position: absolute;
                    width: 30px;
                    height: 3px;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }

        .content_box {
            flex: 1 1 auto;
            padding: 60px 40px;
            box-sizing: border-box;
        }
    }
}

// 文章列表
.article_item {
    font-size: 14px;
    color: #666;
    padding: 15px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
    &:hover {
        background-color: rgb(252, 249, 249);
    }
}

.title {
    color: #666;
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 5px;
}

.pagination {
    margin-top: 20px;
    text-align: right;
}
</style>