import request from '@/utils/request'

// 【前台】资讯中心
export function osCmsCategorys(params) {
    return request({
        url: '/os-cms/categorys',
        method: 'get',
        params
    })
}

// 【前台】获取文章
export function osCmsCategoryPost(params) {
    return request({
        url: '/os-cms/categoryPost',
        method: 'get',
        params
    })
}

// 【前台】获取文章
export function cmsPost(params) {
    return request({
        url: '/cms/post?id=' + params.id,
        method: 'get',
    })
}
