<template>
    <div class="page">
        <Header />
        <div class="page_body">
            <div class="titlt_bg"></div>
            <div class="content">
                <div v-html="html"></div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/header.vue';
import Footer from '@/components/footer.vue';
import { cmsPost } from '@/api/osCms';
export default {
    name: 'Detail',
    components: {
        Header,
        Footer,
    },
    data() {
        return {
            id: undefined,
            html: '',
        };
    },
    created() {
        this.id = this.$route.query.id;
        this.getDetail();
    },
    methods: {
        getDetail() {
            cmsPost({ id: this.id }).then(res => {
                console.log('文章详情', res);
                let data = res;
                if (data.status == 'OK') {
                    this.html = this.formatRichText(data.content.content);
                }
            });
        },
        formatRichText(html) {
            let str = html.replace(/<img[^>]*>/gi, function (match) {
                return match.replace(/style\s*?=\s*?([‘"])[\s\S]*?\1/gi, ''); // 去掉style
            });
            // eslint-disable-next-line no-useless-escape
            str = str.replace(/\<img/gi, '<img style="max-width:100%;"');
            return str;
        },
    },
};
</script>

<style lang="scss" scoped>
// Layout
.page {
    width: 100%;
    background-color: #f4f4f4;

    position: relative;

    .titlt_bg {
        width: 100%;
        height: 450px;
        background-color: #666;
        background: url('../../assets/background/yinsi.png') no-repeat;
        background-size: 100% 100%;
    }

    .content {
        width: 1000px;
        min-height: 500px;
        margin: -80px auto 0 auto;
        background-color: #fff;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        padding: 40px;
        box-sizing: border-box;
    }
}
</style>